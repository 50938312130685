import 'unpoly/unpoly.min'
import 'unpoly/unpoly-bootstrap5.min'
import 'flickity-as-nav-for';
import 'flickity-fade';
import * as CookieConsent from "vanilla-cookieconsent";

import './components/bootstrap'
import './components/aos'
import './components/flickity'
import './components/isotope'
import './components/navbar-dropdown'
import './components/navbar-reveal'
import './components/navbar-toggle'
import './components/smooth-scroll'
import './components/typed'


up.compiler('body', (element) => {
    document.querySelector('html').classList.remove('no-js')

    if (element.getAttribute('data-environment') === 'production') {
        listenForConsent()
        CookieConsent.run({
            mode: 'opt-out',
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    equalWeightButtons: true,
                    flipButtons: false
                },
            },
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true
                },
                functional: {
                    enabled: true,
                },
                analytics: {
                    enabled: true,
                },
                advertisement: {
                    enabled: true
                }
            },
            language: {
                default: 'de',
                autoDetect: 'document',
                translations: {
                    de: {
                        consentModal: {
                            title: "Cookies",
                            description: "Wie die meisten Websites verwenden auch wir Cookies, um Ihr Nutzungserlebnis zu verbessern.",
                            acceptAllBtn: "Alle Cookies akzeptieren",
                            acceptNecessaryBtn: "Nur notwendige Cookies akzeptieren",
                            showPreferencesBtn: "Cookies verwalten",
                        },
                        preferencesModal: {
                            title: "Cookie-Einstellungen",
                            acceptAllBtn: "Alle Cookies akzeptieren",
                            acceptNecessaryBtn: "Nur notwendige Cookies akzeptieren",
                            savePreferencesBtn: "Aktuelle Auswahl akzeptieren",
                            closeIconLabel: "Schliessen",
                            serviceCounterLabel: "Dienste",
                            sections: [
                                {
                                    "title": "Datenschutz",
                                    "description": "Hier findest Du eine Übersicht über alle verwendeten Cookies. Du kannst Deine Einwilligung zu ganzen Kategorien geben oder dir weitere Informationen anzeigen lassen und so nur bestimmte Cookies auswählen."
                                },
                                {
                                    "title": "Notwendige Cookies",
                                    "description": "Notwendige Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.",
                                    linkedCategory: 'necessary'
                                },
                                {
                                    "title": "Funktionale Cookies",
                                    "description": "Funktionale Cookies ermöglichen es, Informationen zu speichern, die das Erscheinungsbild oder die Handhabung der Website beeinflussen, wie z.B. Deine bevorzugte Sprache oder die Region, in der Du dich befindest.",
                                    linkedCategory: 'functional',
                                },
                                {
                                    "title": "Analytische Cookies",
                                    "description": "Analytische Cookies helfen uns, das Nutzungsverhalten auf unserer Website zu analysieren und zu verstehen, wie Besucher mit der Website interagieren. Die Informationen werden anonym gesammelt und gemeldet.",
                                    linkedCategory: 'analytics',
                                },
                                {
                                    "title": "Marketing-Cookies",
                                    "description": "Marketing-Cookies werden verwendet, um Besucher auf Websites zu verfolgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.",
                                    linkedCategory: 'advertisement',
                                },
                            ],
                        }
                    },
                }
            }
        })
    }
})

function listenForConsent() {
    if (window._ccRun) return;

    window.dataLayer = window.dataLayer || [];

    function gtag() {
        window.dataLayer.push(arguments);
    }

    gtag('consent', 'default', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
    });

    const tagManagerScript = document.createElement('script')
    tagManagerScript.setAttribute('src', 'https://www.googletagmanager.com/gtm.js?id=GTM-TDZ9DF5')
    document.head.appendChild(tagManagerScript)

    window.dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'});

    const updateGtagConsent = () => {
        gtag('consent', 'update', {
            'ad_storage': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
            'ad_user_data': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
            'ad_personalization': CookieConsent.acceptedCategory('advertisement') ? 'granted' : 'denied',
            'analytics_storage': CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
            'functionality_storage': CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
            'personalization_storage': CookieConsent.acceptedCategory('functional') ? 'granted' : 'denied',
            'security_storage': 'granted',
        });
    };

    window.addEventListener('cc:onConsent', () => {
        updateGtagConsent();
    });

    window.addEventListener('cc:onChange', () => {
        updateGtagConsent();
    });
}
