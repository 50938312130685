//
// isotope.js
//

import Isotope from 'isotope-layout';

const isotopes = document.querySelectorAll('[data-isotope]');
const toggles = document.querySelectorAll('[data-filter]');
const events = ['click'];

window.onload = () => {
  // Init isotope
  isotopes.forEach((isotope) => {
    const instance = Isotope.data(isotope);

    instance.layout();
  });

  // Filter items
  toggles.forEach((toggle) => {
    if (toggle.dataset.filter === '*')
      toggle.classList.add('active')

    toggle.addEventListener(events[0], (e) => {
      e.preventDefault();

      const filter = toggle.dataset.filter;
      const target = document.querySelector(toggle.dataset.target);
      const instance = Isotope.data(target);
      toggles.forEach((item) => item.classList.remove('active'))
      toggle.classList.add('active')

      instance.arrange({
        filter: filter,
      });
    });
  });
};

// Make available globally
window.Isotope = Isotope;
